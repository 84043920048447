.App {
  text-align: center;
}
.btn{
  padding: 10px 10px;
  background-color: rgb(141, 227, 10);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3, color 0.3;
}
.btn:hover{
  background-color: rgb(17, 231, 17);
  color: black;
}
.qrcode{
  margin-top: 5px;
  margin-bottom: 2px;
}


/* form */
.form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form-group textarea {
  resize: vertical; /* Allow vertical resizing */
}

.form-group input[type="submit"] {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}
.error-message{
  color: red;
}